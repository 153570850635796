import { Button } from 'components/ui/button';
import { Card } from 'components/ui/card';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export default function LocationAdded() {
  const location = useLocation();
  const history = useHistory();

  const queryParams = new URLSearchParams(location.search);
  const name = decodeURIComponent(queryParams.get('name'));
  const locationId = decodeURIComponent(queryParams.get('id'));

  // If the reload param is true, force page reload. This updates the permissions and the locations in the permissions object to include the newly added location
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const reloadParam = queryParams.get('reload');

    if (reloadParam === 'true') {
      // Remove 'reload=true' from the URL and reload the page
      queryParams.delete('reload');
      history.replace({
        pathname: location.pathname,
        search: queryParams.toString()
      });
      window.location.reload();
    }
  }, [location, history]);

  return (
    <div className="flex justify-center pt-10 bg-gray-100 min-h-screen">
      <div className="max-w-4xl w-full px-4">
        <Card className="p-10 shadow-lg">
          <div className="text-center mb-8">
            <p className="text-2xl font-semibold mb-4">Congrats, your new location has been added successfully!</p>
          </div>
          <div className="flex justify-center space-x-5">
            <Button onClick={() => history.push(`/edit-location/${locationId}?name=${name}`)} variant="outline">
              View Location
            </Button>
            <Button onClick={() => history.push('/add-location')} variant="secondary">
              Add Another Location
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
}
