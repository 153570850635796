import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card';
import { Step, Stepper } from 'components/ui/stepper';
import { useToast } from 'components/ui/use-toast';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { createNewLocationAPI } from '../../services/AddLocation';
import LocationDetailsForm from './LocationDetailsForm';
import LotDetailsForm from './LotDetailsForm';
import SearchableMap from './SearchableMap';

const steps = [
  { key: 'selectLocation', label: 'Select Location' },
  { key: 'locationDetails', label: 'Location Details' },
  { key: 'lotDetails', label: 'Lot Details' }
];

export default function AddLocationCard() {
  const [searchedPlaceDetails, setSearchedPlaceDetails] = useState({});
  const [locationDetailsFormData, setLocationDetailsFormData] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [lotDetailsFormData, setLotDetailsFormData] = useState({});
  const [savingNewLocation, setSavingNewLocation] = useState(false);
  const [error, setError] = useState(null);
  const { toast } = useToast();
  const history = useHistory();

  const onConfirmLocation = (placeDetails) => {
    setSearchedPlaceDetails(placeDetails);
  };

  const onEnterLocationDetails = (formData) => {
    setLocationDetailsFormData(formData);
  };

  const createLocation = async (lotDetailsFormData) => {
    toast({
      title: 'Saving new location...',
      description: 'Please be patient. This may take a while'
    });
    setSavingNewLocation(true);
    try {
      const { success, data } = await createNewLocationAPI({
        payload: {
          locationName: locationDetailsFormData.locationName,
          locationId: locationDetailsFormData.locationId,
          fullAddress: searchedPlaceDetails.fullAddress,
          address: locationDetailsFormData.address,
          city: locationDetailsFormData.city,
          state: locationDetailsFormData.state,
          timezone: locationDetailsFormData.timezone,
          version: lotDetailsFormData.version,
          lanes: lotDetailsFormData.lanes,
          locationCoords: searchedPlaceDetails.coords,
          gracePeriod: lotDetailsFormData.gracePeriod,
          isCloudpark: lotDetailsFormData.isCloudpark,
          serviceFees: lotDetailsFormData.serviceFees,
          additionalTaxPercent: lotDetailsFormData.additionalTaxPercent
        }
      });
      if (!success || !data) {
        throw new Error('Unable to add new location');
      }

      setSavingNewLocation(false);
      toast({
        title: 'New location saved!'
      });
      history.push(
        `/add-location/success?reload=true&name=${encodeURIComponent(locationDetailsFormData.locationName)}&id=${encodeURIComponent(locationDetailsFormData.locationId)}`
      );
    } catch (error) {
      console.error('Error:', error);
      setError({ title: 'Something went wrong!', message: 'Please send an email to jake.conniff@propark.com so we can fix this' });
      setSavingNewLocation(false);
      toast({
        title: 'Uh oh! Something went wrong.',
        description: 'We were unable to add your new location. Please let us know!'
      });
    }
  };

  // eslint-disable-next-line no-unused-vars
  const onResetForm = () => {
    setSearchedPlaceDetails({});
    setLocationDetailsFormData({});
    setLotDetailsFormData({});
  };

  const onEnterLotDetails = async (formData) => {
    setLotDetailsFormData(formData);
    await createLocation(formData);
  };

  const displayStepContent = (stepProps) => {
    switch (stepProps.key) {
      case 'selectLocation':
        return (
          <div className="mr-1 sm:mx-10">
            <SearchableMap onConfirmLocation={onConfirmLocation} />
          </div>
        );

      case 'locationDetails':
        return (
          <div className="sm:mx-10 mr-1">
            <LocationDetailsForm
              defaultValues={{
                ...searchedPlaceDetails,
                locationName: locationDetailsFormData.locationName ?? '',
                locationId: locationDetailsFormData.locationId ?? undefined
              }}
              onSubmitCallback={onEnterLocationDetails}
            />
          </div>
        );
      case 'lotDetails':
        return (
          <div className="sm:mx-10 mr-1">
            <LotDetailsForm onSubmitCallback={onEnterLotDetails} savingNewLocation={savingNewLocation} />
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      <Card>
        <div className="max-w-7xl mx-auto">
          <CardHeader className="pb-0 mb-4 pt-1 md:pt-3">
            <CardTitle className="text-lg md:text-2xl">Add a New Location</CardTitle>
            {/* <CardDescription className="hidden md:block">Add a new parking location to the Lightning Pay system.</CardDescription> */}
          </CardHeader>
          <CardContent className="max-sm:pr-1 max-sm:pl-1">
            <Stepper initialStep={0} steps={steps} className="mb-8">
              {steps.map((stepProps) => (
                <Step {...stepProps}>
                  <div className="mb-8">{displayStepContent(stepProps)}</div>
                </Step>
              ))}

              {error && (
                <div>
                  <p className="text-2xl font-semibold mt-10 mb-5">{error.title}</p>
                  <p className="text-lg mb-2">{error.message}</p>
                </div>
              )}
            </Stepper>
          </CardContent>
        </div>
      </Card>
    </div>
  );
}
