import { PlusCircledIcon } from '@radix-ui/react-icons';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from 'components/ui/alert-dialog';
import { Button } from 'components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogFooter } from 'components/ui/dialog';
import { ScrollArea } from 'components/ui/scroll-area';
import { useToast } from 'components/ui/use-toast';
import { Trash2Icon } from 'lucide-react';
import { useMemo, useState } from 'react';
import AddEFRDialog from './AddEFRDialog';

export default function EditEFRDialog({ open, onClose, efrs, pendingChanges, setPendingChanges }) {
  const [addEFROpen, setAddEFROpen] = useState(false);
  const [proposedDeleteEFR, setProposedDeleteEFR] = useState(null);
  const { toast } = useToast();

  const displayEFRs = useMemo(() => {
    const existingEFRs = efrs.filter((efr) => !pendingChanges.deleted.includes(efr.id));
    return [...existingEFRs, ...pendingChanges.added];
  }, [efrs, pendingChanges]);

  const onAddEFR = (newEFR) => {
    setAddEFROpen(false);
    setPendingChanges((prev) => ({
      ...prev,
      added: [...prev.added, newEFR]
    }));
    toast({
      title: 'New early bird rate added to pending changes.',
      description: "Don't forget to save your changes!"
    });
  };

  const onConfirmDelete = () => {
    if (pendingChanges.added.some((efr) => efr.id === proposedDeleteEFR.id)) {
      // If it's a newly added EFR, remove it from pendingChanges.added
      setPendingChanges((prev) => ({
        ...prev,
        added: prev.added.filter((efr) => efr.id !== proposedDeleteEFR.id)
      }));
    } else {
      // If it's an existing EFR, add it to pendingChanges.deleted
      setPendingChanges((prev) => ({
        ...prev,
        deleted: [...prev.deleted, proposedDeleteEFR.id]
      }));
    }
    setProposedDeleteEFR(null);
    toast({
      title: 'Early Bird rate removed from changes.',
      description: 'Remember to save your changes to apply this deletion.'
    });
  };

  const displayEFR = (efr) => (
    <div className="flex border-b p-4 space-x-6 justify-between" key={efr.id}>
      <div>
        <p className="text-sm text-center ">Parker enters between:</p>
        <p className="text-sm text-center ">
          <span className="font-bold">{efr.entry.format('ddd h:mm A')}</span> &{' '}
          <span className="font-bold">{efr.entryCutoff.format('ddd h:mm A')}</span>
        </p>
      </div>
      <div>
        <p className="text-sm text-center ">And leaves before:</p>
        <p className="text-sm font-bold text-center">{efr.exit.format('ddd h:mm A')}</p>
      </div>

      <div>
        <p className="text-sm text-center ">Parker pays:</p>
        <p className="text-sm font-bold text-center">${efr.price}</p>
      </div>

      <Button
        className="justify-center"
        variant="ghost"
        size="icon"
        onClick={() => setProposedDeleteEFR(efr)}
        disabled={pendingChanges.deleted.includes(efr.id)}>
        <Trash2Icon className="h-5 w-5" />
      </Button>
    </div>
  );

  return (
    <div>
      <Dialog open={open} onOpenChange={(isOpen) => !isOpen && onClose()}>
        <DialogContent
          className="sm:max-w-[700px]"
          onInteractOutside={(e) => {
            e.preventDefault();
          }}>
          <DialogHeader>
            <DialogTitle>Edit early bird rates</DialogTitle>
            <DialogDescription>
              If a parking duration falls within the specified early bird times, the parker will be charged the early bird rate regardless of your
              regular rate schedule.
            </DialogDescription>
          </DialogHeader>
          <ScrollArea className="h-96 rounded-md border">
            <div className="p-4">
              {displayEFRs.map((efr) => displayEFR(efr))}
              {!displayEFRs.length && <p className="text-md text-center font=bold">This location currently has no early bird rates.</p>}
            </div>
          </ScrollArea>
          <div className="flex justify-center items-center">
            <Button onClick={() => setAddEFROpen(true)} variant="outline">
              <PlusCircledIcon className="mr-2 h-4 w-4" />
              Add New
            </Button>
          </div>
          <DialogFooter>
            <Button type="submit" onClick={() => onClose()}>
              Confirm
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      {addEFROpen && <AddEFRDialog open={addEFROpen} onClose={() => setAddEFROpen(false)} onSubmit={onAddEFR} />}
      <AlertDialog open={!!proposedDeleteEFR}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure you want to delete this early bird rate?</AlertDialogTitle>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setProposedDeleteEFR(null)}>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={onConfirmDelete}>Continue</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}
