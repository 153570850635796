import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';

export default function TimePicker({ value, onChange }) {
  return (
    <div className="flex space-x-2">
      <Select onValueChange={(newVal) => onChange({ ...value, hrs: newVal })} value={value.hrs}>
        <SelectTrigger className="w-[80px]">
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="01">01</SelectItem>
            <SelectItem value="02">02</SelectItem>
            <SelectItem value="03">03</SelectItem>
            <SelectItem value="04">04</SelectItem>
            <SelectItem value="05">05</SelectItem>
            <SelectItem value="06">06</SelectItem>
            <SelectItem value="07">07</SelectItem>
            <SelectItem value="08">08</SelectItem>
            <SelectItem value="09">09</SelectItem>
            <SelectItem value="10">10</SelectItem>
            <SelectItem value="11">11</SelectItem>
            <SelectItem value="12">12</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>

      <Select onValueChange={(newVal) => onChange({ ...value, mins: newVal })} value={value.mins}>
        <SelectTrigger className="w-[80px]">
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="00">00</SelectItem>
            <SelectItem value="05">05</SelectItem>
            <SelectItem value="10">10</SelectItem>
            <SelectItem value="15">15</SelectItem>
            <SelectItem value="20">20</SelectItem>
            <SelectItem value="25">25</SelectItem>
            <SelectItem value="30">30</SelectItem>
            <SelectItem value="35">35</SelectItem>
            <SelectItem value="40">40</SelectItem>
            <SelectItem value="45">45</SelectItem>
            <SelectItem value="50">50</SelectItem>
            <SelectItem value="55">55</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>

      <Select onValueChange={(newVal) => onChange({ ...value, ampm: newVal })} value={value.ampm}>
        <SelectTrigger className="w-[80px]">
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="AM">AM</SelectItem>
            <SelectItem value="PM">PM</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>

      <Select onValueChange={(newVal) => onChange({ ...value, dow: newVal })} value={value.dow}>
        <SelectTrigger className="w-[125px]">
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="Sunday">Sunday</SelectItem>
            <SelectItem value="Monday">Monday</SelectItem>
            <SelectItem value="Tuesday">Tuesday</SelectItem>
            <SelectItem value="Wednesday">Wednesday</SelectItem>
            <SelectItem value="Thursday">Thursday</SelectItem>
            <SelectItem value="Friday">Friday</SelectItem>
            <SelectItem value="Saturday">Saturday</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
}
