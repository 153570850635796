import { zodResolver } from '@hookform/resolvers/zod';
import { DotFilledIcon } from '@radix-ui/react-icons';
import { Button } from 'components/ui/button';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip';
import { MultipleSelector } from 'components/ui/multiple-selector';
import { RadioGroup, RadioGroupItem } from 'components/ui/radio-group';
import { Separator } from 'components/ui/separator';
import { Switch } from 'components/ui/switch';
import { Textarea } from 'components/ui/textarea';
import { cn } from 'lib/utils';
import { useFieldArray, useForm } from 'react-hook-form';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { z } from 'zod';
import ManagePhotos from './ManagePhotos';
import BlackoutDates from './BlackoutDates/BlackoutDates';
import { useState } from 'react';
import dayjs from 'dayjs';

const MAX_NUM_ABOUT_BULLET_POINTS = 5;

const multiSelectOptionSchema = z.object({
  label: z.string(),
  value: z.union([z.string(), z.number()]),
  disable: z.boolean().optional()
});

const trFormSchema = z.object({
  gracePeriod: z.preprocess(
    (x) => (x === null || x === undefined ? undefined : x),
    z.coerce
      .number({
        required_error: 'Please input a number of minutes.',
        invalid_type_error: 'Please input a number'
      })
      .min(0, {
        message: 'Cannot have a grace period of less than 0'
      })
      .max(1440, {
        message: 'Cannot have a grace period of more than 1440 mins (24 hours)'
      })
  ),
  phone: z
    .string({
      required_error: 'Please input a phone number',
      invalid_type_error: 'Please input a phone number'
    })
    .refine((value) => /^\d{10}$/.test(value.replace(/\D/g, '')), {
      message: 'Phone number must contain 10 digits'
    }),
  heightRestriction: z.preprocess(
    (x) => {
      if (x === '') {
        return undefined;
      }
      if (x !== null && x !== undefined) {
        return String(x).replace(/[\$,%]/g, '');
      }
      return x;
    },
    z.coerce
      .number({
        required_error: 'Please input a number',
        invalid_type_error: 'Please input a number'
      })
      .positive({
        message: 'Must be greater than 0'
      })
      .max(1000, {
        message: 'Must be less than 1000'
      })
      .optional()
  ),
  email: z.string().optional(),
  gettingThere: z.string().max(500).min(4),
  amenities: z.array(multiSelectOptionSchema),
  parkingInfo: z.union([z.string(), z.number()]),
  aboutLocationPoints: z.array(
    z.object({
      value: z
        .string()
        .min(1, {
          message: 'Please fill out this bullet point.'
        })
        .max(200, {
          message: 'Must be less than 200 characters'
        })
    })
  ),
  maxConcurrentStays: z.preprocess((value) => {
    if (value === null || value === undefined) {
      return undefined;
    }
    if (value === '') {
      return null;
    }
    return value;
  }, z.coerce.number().nullable().optional()),
  enableTrs: z.boolean()
});

export default function TransientReservationsTab({ locationId, defaultValues, onSubmit, disabled, isTrEnabledSaving, canEdit }) {
  const [selectedAddBlackoutDates, setSelectedAddBlackoutDates] = useState([]);
  const [selectedRemoveBlackoutDates, setSelectedRemoveBlackoutDates] = useState([]);
  const form = useForm({
    resolver: zodResolver(trFormSchema),
    defaultValues,
    mode: 'onChange'
  });

  const aboutLocationPoints = useFieldArray({
    name: 'aboutLocationPoints',
    control: form.control
  });

  const onSubmitForm = async (data) => {
    const numbersOnlyPhone = data.phone.replace(/\D/g, '');
    const facilitiesObject = {
      Phone: data.phone
    };
    if (data.heightRestriction) {
      facilitiesObject['Height Restriction'] = `${data.heightRestriction} inches`;
    }
    const aboutLocation = data?.aboutLocationPoints.map((el) => el.value) ?? [];
    const trAmenities = data?.amenities.map((el) => el.value) ?? [];
    onSubmit({
      ...data,
      phone: Number(numbersOnlyPhone),
      facilitiesObject,
      aboutLocation,
      trAmenities,
      addTrBlackoutDates: selectedAddBlackoutDates.length > 0 ? selectedAddBlackoutDates : undefined,
      removeTrBlackoutDates: selectedRemoveBlackoutDates.length > 0 ? selectedRemoveBlackoutDates : undefined
    });
    setSelectedAddBlackoutDates([]);
    setSelectedRemoveBlackoutDates([]);
  };
  const convertedSelectedDates = () => {
    const dates =
      defaultValues?.blackOutDates?.map((date) => {
        const requiredDate = date.blackout_date.split('T')[0];

        const newDate = dayjs(requiredDate).toDate();

        return newDate;
      }) || [];
    return [...dates];
  };
  const [selectedDatesData, setSelectedDatesData] = useState(convertedSelectedDates());
  return (
    <div className="space-y-6">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmitForm)}>
          <div className="max-w-2xl text-left space-y-3 sm:space-y-8 mb-4">
            <FormField
              control={form.control}
              name="enableTrs"
              render={({ field }) => (
                <FormItem>
                  <div className="flex items-center justify-between max-w-2xl">
                    <div className="mr-2 text-left">
                      <FormLabel className="text-lg font-medium">Transient Reservations</FormLabel>
                      <FormDescription className="text-sm text-muted-foreground">
                        Update your settings for the Transient Reservations system.
                      </FormDescription>
                    </div>
                    <FormControl>
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <div className="flex items-center space-x-3">
                              <Switch
                                id="enabled"
                                className="scale-125"
                                checked={field.value}
                                disabled={!defaultValues.canBeEnabled || isTrEnabledSaving || !canEdit}
                                onCheckedChange={field.onChange}
                              />
                              <Label htmlFor="enabled">Enabled</Label>
                            </div>
                          </TooltipTrigger>
                          {!defaultValues.canBeEnabled && (
                            <TooltipContent className="max-w-xs">
                              <p>
                                You need to fill out all transient reservation settings and click update before transient reservations can be enabled
                              </p>
                            </TooltipContent>
                          )}
                        </Tooltip>
                      </TooltipProvider>
                    </FormControl>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Separator />
            <FormField
              control={form.control}
              name="gracePeriod"
              render={({ field }) => (
                <FormItem>
                  <div className="flex justify-between items-center">
                    <div className="mb-3 mr-2 text-left">
                      <FormLabel>Grace Period</FormLabel>
                      <FormDescription>How many minutes can parkers park without being charged?</FormDescription>
                    </div>
                    <FormControl>
                      <Input placeholder="30" {...field} className="w-28" disabled={!canEdit} />
                    </FormControl>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="maxConcurrentStays"
              render={({ field }) => {
                const { ref, ...rest } = field;
                return (
                  <FormItem>
                    <div className="flex justify-between items-center">
                      <div className="mb-3 mr-2 text-left">
                        <FormLabel>Max concurrent stays</FormLabel>
                        <FormDescription>This is equivalent to the number of reservable spaces in the lot.</FormDescription>
                      </div>
                      <FormControl>
                        <NumericFormat
                          className="w-32"
                          {...rest}
                          customInput={Input}
                          allowNegative={false}
                          placeholder="Stays (Optional)"
                          disabled={disabled}
                        />
                      </FormControl>
                    </div>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name="gettingThere"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Getting There</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Describe how the parker can get to your lot"
                      className="resize-none min-h-[80px]"
                      {...field}
                      disabled={!canEdit}
                    />
                  </FormControl>
                  {/* <FormDescription>This will help the parker find your lot</FormDescription> */}
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="amenities"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Amenities</FormLabel>
                  <FormControl>
                    <MultipleSelector
                      {...field}
                      hidePlaceholderWhenSelected
                      defaultOptions={defaultValues.amenitiesOptions}
                      badgeClassName="rounded-full"
                      placeholder="Select the amenities at your lot..."
                      disabled={!canEdit}
                      emptyIndicator={<p className="text-center text-md leading-5 text-gray-600 dark:text-gray-400">No results found.</p>}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="parkingInfo"
              render={({ field }) => (
                <FormItem>
                  <div className="mb-3 text-left">
                    <FormLabel>Parking Info</FormLabel>
                    {/* <FormDescription>Please choose your parking info</FormDescription> */}
                  </div>
                  <FormControl>
                    <RadioGroup onValueChange={field.onChange} defaultValue={field.value} className="space-y-1" disabled={!canEdit}>
                      {defaultValues.parkingInfoOptions.map((el) => (
                        <FormItem className="flex items-center space-x-3 space-y-0" key={el.id}>
                          <FormControl>
                            <RadioGroupItem value={el.id} />
                          </FormControl>
                          <FormLabel className="font-normal">{el.name}</FormLabel>
                        </FormItem>
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="space-y-4">
              <FormField
                control={form.control}
                name="phone"
                render={({ field }) => {
                  const { ref, ...rest } = field;
                  return (
                    <FormItem className="space-y-1">
                      <div>
                        <Label>Facility Info</Label>
                        <FormDescription className="mt-1">Customer contact number</FormDescription>
                      </div>

                      <FormControl>
                        <PatternFormat
                          className="w-44"
                          {...rest}
                          customInput={Input}
                          format="(%%%) %%%-%%%%"
                          patternChar="%"
                          placeholder="Phone number"
                          disabled={!canEdit}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name="heightRestriction"
                render={({ field }) => {
                  const { ref, ...rest } = field;
                  return (
                    <FormItem className="mr-2 space-y-1">
                      <FormDescription>Height restriction (inches)</FormDescription>

                      <FormControl>
                        {/* <Input {...field} placeholder="Height Restriction" /> */}
                        <NumericFormat
                          className="w-44"
                          {...rest}
                          customInput={Input}
                          thousandSeparator=","
                          allowNegative={false}
                          placeholder="Height (Optional)"
                          disabled={!canEdit}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
            </div>
            <div>
              {aboutLocationPoints.fields.map((field, index) => (
                <FormField
                  control={form.control}
                  key={field.id}
                  name={`aboutLocationPoints.${index}.value`}
                  render={({ field }) => (
                    <FormItem>
                      <div>
                        <FormLabel className={cn(index !== 0 && 'sr-only')}>About Location</FormLabel>
                        <FormDescription className={cn(index !== 0 && 'sr-only')}>
                          Add information about your location. Will be displayed as a list of bullet points
                        </FormDescription>
                      </div>

                      <div className="flex items-center space-x-2">
                        <DotFilledIcon className="h-6 w-6" />
                        <FormControl>
                          <Input {...field} disabled={!canEdit} />
                        </FormControl>
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              ))}
              <div className="max-sm:w-[170px]">
                <Button
                  type="button"
                  variant="secondary"
                  size="sm"
                  className="mt-2 mr-2"
                  onClick={() => aboutLocationPoints.remove(aboutLocationPoints.fields.length - 1)}
                  disabled={aboutLocationPoints.fields.length <= 1 || !canEdit}>
                  Remove Point
                </Button>
                <Button
                  type="button"
                  variant="outline"
                  size="sm"
                  className="mt-2"
                  onClick={() => aboutLocationPoints.append({ value: '' })}
                  disabled={aboutLocationPoints.fields.length >= MAX_NUM_ABOUT_BULLET_POINTS || !canEdit}>
                  Add Point
                </Button>
              </div>
            </div>
            <ManagePhotos locationId={locationId} initialPhotos={defaultValues?.photos ?? []} disabled={!canEdit} />
            <FormField
              control={form.control}
              name="addBlackoutDates"
              render={() => (
                <FormItem className="mb-3">
                  <FormLabel>Add Blackout Dates</FormLabel>
                  <FormControl>
                    <BlackoutDates
                      control={form.control}
                      name="addBlackoutDates"
                      selectedDates={selectedDatesData}
                      blackoutDatesData={defaultValues?.blackOutDates}
                      setSelectedDates={setSelectedDatesData}
                      setSelectedAddBlackoutDates={setSelectedAddBlackoutDates}
                      setSelectedRemoveBlackoutDates={setSelectedRemoveBlackoutDates}
                      isSaving={disabled}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          {canEdit && (
            <Button type="submit" className="mt-4" disabled={disabled}>
              Save
            </Button>
          )}
        </form>
      </Form>
    </div>
  );
}
