import { Button } from 'components/ui/button';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from 'components/ui/dialog';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { useState } from 'react';
import { convertInputTimeToDayjsTime } from './helpers';
import TimePicker from './TimePicker';

const MAX_EFR_PRICE = 1000;

const validateEFR = (efr) => {
  let editedEFR = {
    ...efr,
    entryCutoff: efr.entry.day() === 6 && efr.entryCutoff.day() === 0 ? efr.entryCutoff.add(7, 'day') : efr.entryCutoff,
    exit: efr.entry.day() === 6 && efr.exit.day() === 0 ? efr.exit.add(7, 'day') : efr.exit
  };

  // If the times are the same we need to subtract 1 second from the intended earlier time
  if (editedEFR.entry.isSame(editedEFR.entryCutoff)) {
    editedEFR = { ...editedEFR, entry: editedEFR.entry.subtract(1, 'second') };
  }
  if (editedEFR.entryCutoff.isSame(editedEFR.exit)) {
    editedEFR = { ...editedEFR, entryCutoff: editedEFR.entryCutoff.subtract(1, 'second') };
  }
  // This last check is needed incase all 3 were the same initially
  if (editedEFR.entry.isSame(editedEFR.entryCutoff)) {
    editedEFR = { ...editedEFR, entry: editedEFR.entry.subtract(1, 'second') };
  }

  let errorMessage = null;
  if (efr.price === undefined) {
    errorMessage = 'Please enter a price for this early bird rate';
  } else if (!editedEFR.entryCutoff.isAfter(editedEFR.entry)) {
    errorMessage = 'Please ensure that the end entry time is later than the start entry time.';
  } else if (!editedEFR.exit.isAfter(editedEFR.entryCutoff)) {
    errorMessage = 'Please ensure that the exit time is later than the entry times.';
  }

  return { errorMessage, editedEFR };
};

export default function AddEFRDialog({ open, onClose, onSubmit }) {
  const [entryTime, setEntryTime] = useState({ hrs: '12', mins: '00', ampm: 'AM', dow: 'Monday' });
  const [entryCutoffTime, setEntryCutoffTime] = useState({ hrs: '12', mins: '00', ampm: 'AM', dow: 'Monday' });
  const [exitTime, setExitTime] = useState({ hrs: '12', mins: '00', ampm: 'AM', dow: 'Monday' });
  const [price, setPrice] = useState(undefined);
  const [formError, setFormError] = useState(null);

  const onAddRate = () => {
    const efr = {
      entry: convertInputTimeToDayjsTime(entryTime),
      entryCutoff: convertInputTimeToDayjsTime(entryCutoffTime),
      exit: convertInputTimeToDayjsTime(exitTime),
      price: parseFloat(price)
    };
    const { errorMessage, editedEFR } = validateEFR(efr);
    if (errorMessage) {
      setFormError(errorMessage);
      return;
    }

    setFormError(null);

    const newEFR = {
      ...editedEFR,
      id: `temp_${Date.now()}`
    };

    onSubmit(newEFR);
    onClose();
  };

  const handlePriceInputChange = (event) => {
    const value = event.target.value;
    const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
    if (validated) {
      if (parseFloat(validated) > MAX_EFR_PRICE) {
        setPrice(MAX_EFR_PRICE.toString());
        return;
      }
      if (parseFloat(validated) < 0) {
        setPrice('0');
        return;
      }

      setPrice(value);
    }
  };

  return (
    <Dialog open={open} onOpenChange={(isOpen) => !isOpen && onClose()}>
      <DialogContent
        className="sm:max-w-[500px]"
        onInteractOutside={(e) => {
          e.preventDefault();
        }}>
        <DialogHeader>
          <DialogTitle>Add early bird rate</DialogTitle>
        </DialogHeader>
        <div>
          <p className="text-center text-md font-bold mb-2"> Parker enters between</p>

          <div className="flex justify-center">
            <TimePicker value={entryTime} onChange={setEntryTime} />
          </div>
          <p className="text-center text-md font-bold mb-1 mt-1">&</p>

          <div className="flex justify-center">
            <TimePicker value={entryCutoffTime} onChange={setEntryCutoffTime} />
          </div>
          <p className="text-center text-md font-bold mt-10 mb-2"> And parker exits before</p>
          <div className="flex justify-center">
            <TimePicker value={exitTime} onChange={setExitTime} />
          </div>

          <p className="text-center text-md font-bold mt-10 mb-2">Parker pays</p>
          <div className="flex justify-center items-center">
            <Label className="text-xl mr-3">$</Label>
            <Input className="w-16" value={price} onChange={handlePriceInputChange} />
          </div>
          {formError && <p className="text-[0.8rem] font-medium text-destructive text-center mt-1">{formError}</p>}
        </div>

        <DialogFooter>
          <Button type="submit" onClick={onAddRate}>
            Add Rate
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
